<template>
  <div id="schedule">
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="">Start Date</label>
          <input type="date" v-model="start_date" class="form-control" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="">End Date</label>
          <input type="date" class="form-control" v-model="end_date" />
        </div>
      </div>

      <div class="col-md-2">
        <button class="btn btn-outline-info mt-8" @click="searchData()">
          Search
        </button>
      </div>

      <div class="col-md-6" v-if="showSetBasePlanBtn">
        <button
          class="btn btn-warning mt-8 ml-2"
          style="float: right"
          @click="makeHoliday()"
          ref="kt_make_holiday"
        >
          Make Holiday
        </button>
        <button
          class="btn btn-success mt-8"
          style="float: right"
          @click="setBasePlan()"
        >
          Set Base Plan
        </button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12" style="background: white">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                <input
                  type="checkbox"
                  v-model="checkAllResult"
                  @change="checkAllResult_()"
                />
              </th>
              <th scope="col">Day</th>
              <th scope="col">Date</th>
              <th scope="col" class="text-center">Scheduled</th>
              <th scope="col" class="text-center">Holiday</th>
              <th scope="col" class="text-center">Active</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in resultsTemp" :key="index">
              <td>
                <input
                  :disabled="item.is_scheduled"
                  type="checkbox"
                  @change="selectSchedule"
                  v-model="item.is_checked_for_action"
                />
              </td>
              <td>
                {{ item.dayname }}
              </td>

              <td>{{ item.schedule_date | dateFormat }}</td>

              <td class="text-center">
                <i
                  v-if="item.is_scheduled && !item.is_holiday"
                  class="fa fa-check text-success"
                ></i>
                <i v-else class="fa fa-times text-danger"></i>
              </td>
              <td class="text-center">
                <i v-if="item.is_holiday" class="fa fa-check text-success"></i>
                <i v-else class="fa fa-times text-danger"></i>
              </td>
              <td>
                <span class="switch" v-if="item.id != 0">
                  <input
                    @change="changeStatus(item.id, item.is_active, index)"
                    type="checkbox"
                    v-model="item.is_active"
                    class="switch"
                    :id="'is_active_switch' + item.id"
                  />
                  <label :for="'is_active_switch' + item.id"></label>
                </span>
              </td>
              <td>
                <i
                  class="fa fa-edit text-dark mr-3"
                  style="cursor:pointer"
                  v-if="item.is_scheduled"
                  @click="updateSchedule(item)"
                ></i>

                <i
                  class="fa fa-trash text-danger"
                  style="cursor:pointer"
                  v-if="item.is_scheduled"
                  @click="deleteSchedule(item.id, item.in_use)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {
  STORE_SCHEDULE_HOLIDAY,
  FETCH_SCHEDULES,
  DELETE_SCHEDULES,
  UPDATE_SCHEDULE_STATUS
} from "@/core/services/store/actions.type";

import { SET_SCHEDULE_TEMP_DATA } from "@/core/services/store/mutations.type";
export default {
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      start_date: this.$moment(new Date()).format("YYYY-MM-DD"),
      end_date: this.$moment(new Date(), "YYYY-MM-DD")
        .add(7, "days")
        .format("YYYY-MM-DD"),
      interval: 0,
      resultsTemp: [],
      temp_start_date: "",
      temp_end_date: "",
      old_temp_start_date: 0,
      checkAllResult: false,
      showSetBasePlanBtn: false
    };
  },
  mounted() {
    this.searchData();
  },
  filters: {
    dateFormat(date) {
      return moment(date).format("MM/DD/YYYY");
    }
  },
  methods: {
    changeStatus(id, is_active, index) {
      var ac_ = is_active ? "active" : "inactive";
      this.$swal({
        title: "Are you sure?",
        text: "You want to " + ac_ + " this schedule",

        confirmButtonText: "Change",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willChange => {
        if (willChange.isConfirmed) {
          this.$store
            .dispatch(UPDATE_SCHEDULE_STATUS, {
              id: id,
              is_active: is_active
            })
            .then(data => {
              this.$toastr.s(data.msg);
            });
        } else {
          is_active = is_active ? false : true;
          this.resultsTemp[index].is_active = is_active;
        }
      });
    },
    deleteSchedule(id, inUse) {
      if (inUse) {
        this.$toastr.e(
          "Appointment is already taken for this schedule, can not delete it"
        );
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover !",

          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true
        }).then(willDelete => {
          if (willDelete.isConfirmed) {
            this.$store
              .dispatch(DELETE_SCHEDULES, id)
              .then(res => {
                this.$toastr.s(res.msg);
                this.searchData();
              })
              .catch(errs => {
                this.$toastr.e(errs.detail);
              });
          }
        });
      }
    },
    updateSchedule(item) {
      var results = [];
      results.push(item);
      this.$store.commit(SET_SCHEDULE_TEMP_DATA, results);
      this.$router.push({
        name: "client.appointment.schedule.baseplan.update",
        params: { id: item.id }
      });
    },
    setBasePlan() {
      var results = [];
      this.resultsTemp.forEach(item => {
        if (item.is_checked_for_action) {
          var item_new = {
            schedule_date: item.schedule_date,
            is_holiday: false,
            is_scheduled: true,
            client: item.client,
            id: item.id
          };
          results.push(item_new);
        }
      });
      this.$store.commit(SET_SCHEDULE_TEMP_DATA, results);
      this.$router.push({ name: "client.appointment.schedule.baseplan.store" });
    },
    makeHoliday() {
      var results = [];
      this.resultsTemp.forEach(item => {
        if (item.is_checked_for_action) {
          var item_new = {
            schedule_date: item.schedule_date,
            is_holiday: true,
            is_scheduled: true,
            client: item.client,
            id: item.id
          };
          results.push(item_new);
        }
      });
      var formData = {
        data: results
      };
      const submitButton = this.$refs["kt_make_holiday"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.$store
        .dispatch(STORE_SCHEDULE_HOLIDAY, formData)
        .then(res => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.searchData();
        })
        .catch(errs => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e(errs.detail);
        });
    },
    selectSchedule() {
      this.showSetBasePlanBtn = false;
      this.resultsTemp.forEach(element => {
        if (
          element.is_checked_for_action &&
          !element.is_holiday &&
          !element.is_scheduled
        ) {
          this.showSetBasePlanBtn = true;
        }
      });
    },
    checkAllResult_() {
      this.showSetBasePlanBtn = false;
      if (this.checkAllResult) {
        this.showSetBasePlanBtn = true;
        this.resultsTemp.forEach(element => {
          if (!element.is_scheduled) {
            element.is_checked_for_action = true;
          }
        });
      } else {
        this.resultsTemp.forEach(element => {
          if (!element.is_scheduled) {
            element.is_checked_for_action = false;
          }
        });
      }
    },
    searchData() {
      this.resultsTemp = [];
      this.results = [];
      this.checkAllResult = false;
      this.showSetBasePlanBtn = false;
      var diffDays = this.$moment
        .duration(
          this.$moment(this.end_date).diff(this.$moment(this.start_date))
        )
        .asDays();

      for (let index = 0; index <= diffDays; index++) {
        var date_ = this.$moment(this.$moment(this.start_date), "YYYY-MM-DD")
          .add(index, "days")
          .format("YYYY-MM-DD");
        this.resultsTemp.push({
          dayname: this.$moment(date_).format("dddd"),
          schedule_date: date_,
          is_holiday: false,
          client: this.currentUser.id,
          base_plan_id: -1,
          is_scheduled: false,
          is_active: false,
          in_use: false,
          is_checked_for_action: false,
          id: 0
        });
      }
      this.$store.dispatch(FETCH_SCHEDULES).then(res => {
        res.data.forEach(item => {
          var key = this.resultsTemp.findIndex(
            x => x.schedule_date === item.schedule_date
          );
          if (key != -1) {
            this.resultsTemp[key].is_holiday = item.is_holiday;
            this.resultsTemp[key].is_scheduled = item.is_scheduled;
            this.resultsTemp[key].is_active = item.is_active;
            this.resultsTemp[key].in_use = item.in_use;
            this.resultsTemp[key].id = item.id;
          }
        });
      });
    }
  }
};
</script>
